import { useState, useRef } from 'react';
import axios from '../api';

const useTextToSpeech = (apiKey, language = 'en-us') => {
//   const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
//   const audioRef = useRef(null); // Reference to the audio element

//   const handleTextChange = (e) => {
//     setText(e.target.value);
//   };

  const handleTextToSpeech = async (text) => {
    // Construct the API URL
    const url = `http://api.voicerss.org/?key=${apiKey}&hl=${language}&src=${encodeURIComponent(text)}&c=MP3`;

    setIsLoading(true);
    try {
      // Make the GET request to VoiceRSS API
      const response = await fetch(url);

      // Create a URL for the audio Blob
      const audioBlob = response.data;
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);

       

    } catch (error) {
      console.error('Error fetching TTS audio:', error);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    audioUrl,
    handleTextToSpeech,
  };
};

export default useTextToSpeech;
