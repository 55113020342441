import React from "react";

export default function UnauthorizedPage() {
    return (
        <div className="flex flex-col items-center justify-center w-screen h-screen gap-8 py-8 bg-gray-50">
          <div className="px-2 py-5 w-1/4 h-1/4">
            <img src="/403_error.png"/>
          </div>
            <h1 className="text-3xl font-bold text-white">Access Denied</h1>
            <p className="text-lg text-green-600 mb-6">
                You do not have permission to view this page. Please contact support or log in with the appropriate credentials.
            </p>

            <div className="flex gap-4">
                <button
                    onClick={() => window.location.href = '/login'}
                    className="px-6 py-2 bg-green-700 text-white rounded-md hover:bg-green-700 transition"
                >
                    Go to Login
                </button>
                <button
                  onClick={() => window.history.back()}
                  className="px-6 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition"
                >
                  Back to Previous Page
                </button>


            </div>
        </div>
    );
}
