import React from "react";
import './paliative.png'

 const  Logo = () => {
            return(
                <>
                     <img src="https://www.jigawapalliative.com.ng/wp-content/uploads/2024/07/e-500-x-199-px-1-e1721594916831.png" className='w-40 h-20'/>
                </>
            )
   };

export default Logo;
