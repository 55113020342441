
// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userRoles = localStorage.getItem('role')?.toLowerCase().split(',') || []; // Assume roles are stored in localStorage

    // Redirect if no token found
    if (!token) {
      navigate('/login'); // Redirect to login if not authenticated
      return;
    }

    if (allowedRoles && userRoles.length > 0 && allowedRoles.some(role => userRoles.includes(role.toLowerCase()))) {
      
      setHasAccess(true); // Grant access if user has an allowed role or if '*' is used
    } else if (allowedRoles) {
      
       localStorage.clear();
      navigate('/unauthorized'); // Redirect if user lacks the required role
    }else{

      setHasAccess(true);

    }
  }, [navigate, allowedRoles]);
  

  // Render children if access is granted, otherwise null or redirect
  return hasAccess ? children : null;
};

export default ProtectedRoute;

// // src/components/ProtectedRoute.js
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// const ProtectedRoute = ({ children, allowedRoles }) => {
//   const navigate = useNavigate();
//   const [hasAccess, setHasAccess] = useState(false);

//   // useEffect(() => {
//   //   const token = localStorage.getItem('token');
//   //   const userRoles = localStorage.getItem('role'); // Assume roles are stored in localStorage

//   //   if (!token) {
//   //     navigate('/login'); // Redirect to login if not authenticated
//   //   } else if (userRoles && allowedRoles?.some(role => userRoles?.includes(role))) {
//   //     setHasAccess(true); // Grant access if user has an allowed role
//   //   } else {
//   //     navigate('/unauthorized'); // Redirect if user lacks the required role
//   //   }
//   // }, [navigate, allowedRoles]);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     const userRoles = localStorage.getItem('role'); // Assume roles are stored in localStorage

//     if (!token) {
//       navigate('/login'); // Redirect to login if not authenticated
//     } 
//   }, [navigate]);


//   // return hasAccess ? children : null; // Render children if access is granted
//   return children; // Render children if access is granted
// };

// export default ProtectedRoute;
