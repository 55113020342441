import React, { useState, useRef, useEffect } from 'react';
import InputField from "../components/InputField";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import $ from 'jquery';
import 'dropify/dist/js/dropify.min.js';
import 'dropify/dist/css/dropify.min.css';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Navigate, useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import { FaCartPlus, FaMinus, FaPlus, FaRedo, FaThumbsUp, FaTrash } from 'react-icons/fa';
import { RadioButton } from 'primereact/radiobutton';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { getProducts } from '../Service/ProductService'
import  ProductService  from '../Service/ProductService'
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator'; // Ensure Paginator is imported correctly
import { Toolbar } from 'primereact/toolbar';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { AutoComplete } from "primereact/autocomplete";
import useTextToSpeech from '../Hook/useTextToSpeech';
import Modal from '../components/Modal'
import { InputOtp } from 'primereact/inputotp';
import { sendOtp, validateOtp } from '../Service/OtpService';


export default function SalesForm() {

    const [processing, setProcessing] = useState(false);
    // const [products, setProducts] = useState([]);
    const [fetchedProducts, setFetchedProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [layout, setLayout] = useState('grid');
    const [customers, setCustomers] = useState([]);
    const [type, setType] = useState('');
    const [isEmploy, setIsEmploy] = useState(false);
    const [disable, setIsDisable] = useState(false);
    const [due, setDue] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalSpendable, setSpendable] = useState(0);
    const [totalCharges, setTotalCharges] = useState(0);
    const [loan, setLoan] = useState([]);
    const [repayment, setRepayment] = useState([]);
    const [netSal, setNetSal] = useState(null);
    const [loanType, setLoanType] = useState('');
    const navigate = useNavigate();
    const customersRef = useRef(Array.from({ length: 100000 }));
    const [loading, setLoading] = useState(false);
    const loadLazyTimeout = useRef();
    const [processingStatus, setProcessingStatus] = useState('');
    const [responseCode, setResponseCode] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [responseRef, setResponseRef ]= useState('');
    const [displayedProducts, setDisplayedProducts] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(3); // Default rows per page
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState(null); // Selected filter value
    const [filterOptions, setFilterOptions] = useState([]); // Dropdown options
    const [isLoading,setIsLoading] =useState(true)
    const apiKey = 'c7389eea00614663a94007a7cfff7a23'; // Replace with your API key
    const {  audioUrl, handleTextChange, handleTextToSpeech } = useTextToSpeech(apiKey);
    const audioRef = useRef(null); // Reference to the audio element
    const [filterValue, setFilterValue] = useState('');
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [visible,setVisible] = useState(false);
    const [otpVisible,setOtpVisible] = useState(false);
    const [token, setTokens] = useState();
    const [otp, setOtp] = useState();
    const [countDown,setCountDown] = useState(600);
    const  [tokenExpired, setTokenExpired] = useState(false);
    const [phone, setPhone] = useState('');
    const [step, setStep] = useState(1); // Step 1: Enter Phone, Step 2: Enter OTP

    const customInput = ({events, props}) => {
        return <><input {...events} {...props} type="text" className="custom-otp-input-sample" />
            {props.id === 2 && <div className="px-3">
                <i className="pi pi-minus text-green-500" />
            </div>}
        </>
    };




    useEffect(()=>{
        const interval = setInterval(() => {
            setCountDown(prevCountDown => prevCountDown - 60);
        }, 1000);
        return () => clearInterval(interval);
        if(countDown === 0){    
            setTokenExpired(true)        
            alert('OTP Expired is expired')
        }

    },[countDown])
    // Using the useTextToSpeech hook
  
    // Handle product input change

 const [formData, setFormData] = useState({
    customer_id: '',
    products: [],
    installments:'',
    status: 'pending',  
    card_id:'',
    payment_type: 'direct',
    note: '',
  });


  const playSoundVolume = () => {
    const audio = new Audio("/volume.mp3");
    audio.play();
  };

  const playSoundProduct = () => {
    const audio = new Audio("/productClick.wav");
    audio.play();
  };

  const playSoundDelete = () => {
    const audio = new Audio("/delete.wav");
    audio.play();
  };



    const toast = useRef(null);
    const showSuccess = (severity, summary, message) => {
        toast.current.show({ severity: severity, summary: summary, detail: message, life: 10000 });
    };


    const  changeQty = (field,qty)=>{

        const data = formData.products[field];
        const currentAmount = data.selling_price * data.quantity

        setTotalAmount((prevState) => (
            (prevState - currentAmount)  +  parseFloat(data.selling_price) * qty
            
        ))

        

    }


    useEffect(() => { 
        let totalSpend = totalAmount-totalCharges;
        setSpendable(parseFloat(netSal*1/3)-totalSpend)

    }, [totalAmount])
    

   const playProductAdded = (text) => {
    //     // Concatenate product name and description to form the text for TTS
    // handleTextToSpeech(text); // Trigger the TTS conversion
    // // Automatically play the audio
    // if (audioRef.current) {
    //     audioRef.current.src = audioUrl; // Update the audio element source
    //     alert(audioUrl)
    //     audioRef.current.play(); // Trigger playback
    //   }

   } 


    // Footer template to show selected customer status
    const panelFooterTemplate = () => (
        <div className="py-2 px-3">
            {selectedCustomer ? (
                <span>
                    <b>{selectedCustomer.full_name}</b>
                </span>
            ) : (
                'No customer selected.'
            )}
        </div>
    );
    
  const handleProductChange = (index, e) => {
    playSoundProduct()
    const { name, value } = e.target;
    const updatedProducts = formData.products.map((product, i) =>
      i === index ? { ...product, [name]: value } : product
    );

        setFormData({ ...formData, products: updatedProducts });
        changeQty(index,e.target.value);
  };

  const handleProductIncrement = (index, value) => {
    playSoundVolume()
    const updatedProducts = formData.products.map((product, i) =>
      i === index ? { ...product, quantity: value } : product
    );

    setFormData({ ...formData, products: updatedProducts });
    changeQty(index, value);
  };

  
  const handleProductDecrement = (index, value) => {
    playSoundVolume()
    const updatedProducts = formData.products.map((product, i) =>
      i === index ? { ...product, quantity: value } : product
    );

    setFormData({ ...formData, products: updatedProducts });
    changeQty(index, value);
  };

//   useEffect(() => {


//   }, [formData.products]);


  const handleAddProduct = (newProduct) => {
    // Assuming e.target.value is an object representing the product
    // const newProduct = e.target.value;
    playSoundProduct()
    const showSuccess = (severity, summary, message) => {
        toast.current.show({ severity: severity, summary: summary, detail: message, life: 10000 });
    };

    if ((newProduct.qty_in - newProduct.qty_out <= 0)) {
        showSuccess('error', "Product"," Product stock is 0");
    }

    // Update the formData with the new product
    setFormData(prevFormData => {
        // Add the new product to the products array
        const updatedProducts = [...prevFormData.products, {
            selling_price: newProduct.selling_price * 1,
            discount: newProduct.discount,
            name: newProduct.name,
            id: newProduct.id,
            stock: (newProduct.qty_in - newProduct.qty_out),
            quantity: 1
        }];



        // Filter out duplicate products based on the product ID
        const uniqueProducts = updatedProducts.filter((product, i, self) =>
            i === self.findIndex((p) => p.id === product.id)

        );

        const productIndex = formData.products.findIndex(product => product.id === newProduct.id);






        // Check if the quantity of the new product is less than or equal to 0
        if ((newProduct.qty_in - newProduct.qty_out) <= 0) {
            // Return the updated formData with unique products
            // Return the updated formData with an empty products array (it looks like a mistake to set it to an empty object `{}`)

                if(productIndex !== -1){

                }else{
                    setTotalAmount((prevState) => (
                        prevState + 0
                    ))
                }

            return {
                ...prevFormData,
                products: formData.products
            };

        } else {


        if(productIndex !== -1){

        }else{
            setTotalAmount((prevState) => (
                prevState + parseFloat(newProduct.selling_price)
            ))
        }


            return {
                ...prevFormData,
                products: uniqueProducts
            };
        }

    });

};


useEffect(() => {
    playProductAdded('Mazie Added')
        setLoanType(formData.payment_type)
}, [formData.payment_type]);

  const handleRemoveProduct = (index) => {
    playSoundDelete()
    const amount = formData.products[index].selling_price;

    const productIndex = formData.products.indexOf(index);


    const updatedProducts = formData.products.filter((_, i) => i !== index);
    setFormData({ ...formData, products: updatedProducts });

    
    if(productIndex !== -1){

    }else{
        const qty = formData.products[index];

        setTotalAmount((prevState) => (
            prevState - (parseFloat(amount) * qty.quantity)
        ))
      
    }

    if(formData.products === null){
        // alert("is empty")
        setTotalAmount(0);
        setTotalCharges(0)
    }


  };

 
    const handleCustomerChange = (e) => {
        const { value } = e.target;

            setSelectedCustomer(value)
            setFormData((prevState) => ({
                ...prevState,
                customer_id: ''
            }))

        
                             
        setFormData((prevState) => ({
            ...prevState,
            customer_id: value
        }));

        
    };



    const searchCustomer = (event) => {
        const query = event.query?.toLowerCase() || ''; // Ensure query is a string
        console.log('Card: ' + query);
    
        const filtered = customers.filter(customer => {
            // Ensure card and card_id exist before attempting to use includes
            const cardId = customer?.card?.card_id || '';
            return cardId.toLowerCase().includes(query);
        });
    
        setFilteredCustomers(filtered);
    };
    
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleInputCard = (card_id) => {
        
        setFormData((prevState) => ({
            ...prevState,
            ['card_id']: card_id
        }));
    };


    // useEffect(() => {
    //     setIsLoading(true)
     
      
    //  }, [])
     
         useEffect(() => {
     
             if(formData?.customer_id?.card?.card_id?.length > 0){
                 setVisible(true)
                 setIsLoading(false)
             }             
         }, [formData.customer_id])
         
     
    const handleOtpSale = async (e) => {
        e.preventDefault();
        setOtpVisible(true)
        try {
            await sendOtp(formData?.customer_id?.psn);
            showSuccess('success','OTP Verify','OTP sent successfully!');
            setStep(2);
        } catch (error) {
            // setMessage(error.response?.data?.error || 'Failed to send OTP');
            showSuccess('error',error.response?.data?.error || 'Failed to send OTP');
        }
    }


    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevState) => ({
            ...prevState,
            payment_type: value
        }));
    };

    const handleTypeChange = (e) => {
        const { name, value } = e.target;
   setType(value)
    };

    const methods = [
        {id:'installment', name:"CREDIT PURCHASE"},
        {id:'card', name:"DIRECT PURCHASE"},        
    ]



const status = [
    {id:'pending',name:"Pending"},
    {id:'completed',name:"Completed"},
    {id:'paid',name:"Paid"},
    {id:'installment',name:"Installment'"},
    {id:'cancelled',name:"Cancelled"}
]




const customerType =[
    { "id": 1, "name": "Individual" },
    { "id": 2, "name": "Employee" },
]



 useEffect(() => {

    if (type.id == customerType[1].id) {
        setIsEmploy(true)

    }else{
        setIsEmploy(false)
    }

 }, [type]);



 
 
 const checkEligibleAmount = (amount) => {
    // Calculate the customer's eligible amount (one-third of the given amount)
   
    let eligibleAmount = (amount/3);
    const charges = totalAmount * 0.035;
    setTotalCharges(charges)
    const totalCost = totalAmount + charges;

    // const apiUrl = process.env.REACT_APP_API_URL;

   

    // Check if the total amount exceeds the eligible amount
    if (totalCost > eligibleAmount && formData.payment_type !== 'card') {

        if (isEmploy) {
            showSuccess(
                'error',
                "Repayment",
                `Your total product amount exceeds your loan eligibility of N ${new Intl.NumberFormat('en-US').format(eligibleAmount)}`
            );
            setIsDisable(true);

        }
      
    }else{
        setIsDisable(false)
    }

}//End of the function



useEffect(() => {
    checkEligibleAmount(netSal);
    // if(totalAmount <= 0){
    //     setIsDisable(false)
    // }
}, [totalAmount]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        

        if(isEmploy){
            if(formData?.card?.card_id == ''){
                showSuccess('error',"Card Error",`Beneficiary must have a valid card linked for palliative`)
                return false;
            }
        }

        setProcessing(true);
    
        // alert(formData?.customer_id?.card?.card_id)
        const saleData = {
        ...formData,
        // installments:parseInt(formData.installments),
        installments: loanType == 'installment' ? 1 : 0,
        customer_id: parseInt(formData.customer_id.id),
        payment_type: formData.payment_type,
        card_id: formData?.customer_id?.card?.card_id,
        total_amount: totalAmount,
        products: formData.products.map(product => ({
        id: parseInt(product.id),
        qty: parseInt(product.quantity),
      })),
    };

    console.log('Sales Data',saleData)

        try {
          

            if(formData.payment_type == 'card'){

                const response = await axios.post('https://api.scan-verify.com/api/sales/create/card',saleData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
         

            setProcessing(false);
            if (response.data.success) {
                fetchProducts()
                const ref = response?.data?.ref;
                    
                if (ref) {
                    setResponseRef(ref)
                  
                    navigate(`/sales/receipt-card/${ref}?status=${response.data.status.processingStatus}`);
                    
                  } else {
             
                    console.error('Reference not found');
                  }


            setFormData((form)=>({
                ...form,
                products:[]
            }))

                showSuccess('success', "Product", response.data.message);
            } else {

                showSuccess('error', "Product", response.data.message);
            }
        
        }else if(formData.payment_type == 'installment'){
                const response = await axios.post('https://api.scan-verify.com/api/sales/create',saleData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
        

            console.log('Product added:', response.data);
            setProcessing(false);
            if (response.data.success) {
                fetchProducts()
                const ref = response?.data?.ref;

                if (ref) {
                    setResponseRef(ref)
                    navigate(`/sales/receipt/${ref}`);
                } else {
                    console.error('Reference not found');
                }

            setFormData((form)=>({
                ...form,
                products:[]
            }))

                showSuccess('success', "Product", response.data.message);
            } else {

                showSuccess('error', "Product", response.data.message);
            }
        }else{
            alert('Failed Transaction')
            setProcessing(false);
        };

    } catch (error) {
        setProcessing(false);
        console.error('Error adding product:', error);
    }
};



const items = [
    {
        label: 'Refresh',
        icon: 'pi pi-refresh'
    }
    // {
    //     label: 'Delete',
    //     icon: 'pi pi-times'
    // }
];

    const handleDecrementProduct = (index) => {
        const products = [...formData.products];
        const product = products[index];
        if (product.quantity > 1) {
            // product.quantity -= 1;
            products[index] = product;
            setFormData((formData) => ({
                ...formData,
                products: products
            }))
            handleProductDecrement(index, product.quantity -1)
        }
    }

    const handleIncrementProduct = (index) => {
        const products = [...formData.products];
        const product = products[index];
        // product.quantity += 1;
        products[index] = product;
        setFormData((formData) => ({
            ...formData,
            products: products
        }))

        handleProductIncrement(index, product.quantity +1)
    }


// const onSearchChange = (e) => {
//     const term = e.target.value.toLowerCase();
//     setSearchTerm(term);
  
//     if (term === '') {
//       setDisplayedProducts(products); // Show all products when search is empty
//     } else {
//       const filtered = products.filter((product) => 
//         product.name.toLowerCase().includes(term)
//       );
//       console.log('Filtered Products:', filtered);
//       setDisplayedProducts(filtered);
//     }
//   };
  
// Update displayed products whenever filteredProducts or pagination changes
useEffect(() => {
    setDisplayedProducts(filteredProducts.slice(first, first + rows));
  }, [filteredProducts]);

  const onSearchChange = (e) => {
    const term = e.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive search
    setSearchTerm(term);

    // Filter products based on the search term
    const filtered = term
      ? fetchedProducts.filter((product) =>
          product.name.toLowerCase().includes(term)
        )
      : fetchedProducts; // Show all products if search term is empty

    setFilteredProducts(filtered);
    setFirst(0); // Reset pagination
  };

  
//   const onSearchChange = (e) => {
//     const term = e.target.value.toLowerCase();
//     setSearchTerm(term); // Update search term
//     setIsLoading(true); // Start loading state

//     // Simulate async behavior for better user experience
//     setTimeout(() => {
//         const filtered = products.filter((product) =>
//             product.name.toLowerCase().includes(term)
//         );
//         setDisplayedProducts(filtered); // Update displayed products
//         setIsLoading(false); // End loading state
//     }, 300); // Delay for smoother UI feedback
// };



const startContent = (
    <React.Fragment>
         <IconField iconPosition="right">
            <InputIcon className="pi pi-search" />
            <InputText placeholder="Search"  value={searchTerm}   onChange={onSearchChange} className='p-inputtext-sm p-2 items-stretch w-full'/>
        </IconField>
    </React.Fragment>
);

const centerContent = (
    <IconField iconPosition="right">
               <Button icon="pi pi-refresh" className="mr-2" />       
    </IconField>
);

const endContent = (
    <React.Fragment>
        <SplitButton label="Filter" model={items} icon="pi pi-check"></SplitButton>
    </React.Fragment>
);


// useEffect(() => {
//     ProductService.getProducts().then((data) => {setFetchedProducts(data.slice(0, 4));
//         console.log(data)
//     });
// }, []);

// useEffect(() => {
//     // Fetch products once and store them
//     ProductService.getProducts().then((data) => {
//       setFetchedProducts(data); // Store all products
//       setDisplayedProducts(data.slice(first, first + rows)); // Display the initial page
//     });
//   }, []);

//   useEffect(() => {
//     // Update displayed products when pagination changes
//     setDisplayedProducts(fetchedProducts.slice(first, first + rows));
//   }, [first, rows, fetchedProducts]);

useEffect(() => {
    ProductService.getProducts().then((data) => {
      setFetchedProducts(data);
      setFilteredProducts(data); // Default: show all products
      setDisplayedProducts(data.slice(0, rows));
      setIsLoading(false);

      // Extract unique filter options, e.g., categories
      const uniqueOptions = [...new Set(data.map((product) => product.name))];

      data.map((product) => product.name);
    console.log('Options'+uniqueOptions)
      setFilterOptions(uniqueOptions.map((option) => ({ label: option, value: option })));
    });
    console.log(filterOptions)
  }, []);

  useEffect(() => {
    // Update displayed products when pagination or filter changes
    setDisplayedProducts(filteredProducts.slice(first, first + rows));
    console.log("Filtered Options"+filterOptions)
  }, [first, rows, filteredProducts]);

  const onPageChange = (event) => {
    setFirst(event.first); // Update the starting index
    setRows(event.rows); // Update the number of rows per page
  };


  const onFilterChange = (e) => {
    const selectedFilter = e.value;
    setFilter(selectedFilter);
    console.log('Filtered'+selectedFilter)
    // Filter products based on the selected filter
    const filtered = selectedFilter
      ? fetchedProducts.filter((product) => product.name === selectedFilter)
      : fetchedProducts;

    setFilteredProducts(filtered);
    setFirst(0); // Reset pagination
    setDisplayedProducts(filtered.slice(0, rows));
  };

const getSeverity = (product) => {
    switch (product) {
        case 'INSTOCK':
            return 'success';

        case 'LOWSTOCK':
            return 'warning';

        case 'OUTOFSTOCK':
            return 'danger';

        default:
            return null;
    }
};

const listItem = (product, index) => {
    return (
        <div className="col-12" key={product.id}>
            <div className={classNames('flex flex-column xl:flex-row xl:align-items-start p-2 gap-2', { 'border-top-1 surface-border': index !== 0 })} onClick={()=>{handleAddProduct(product)}}>
                <img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={product.image} alt={product.name} />
                <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-2">
                    <div className="flex flex-column align-items-center sm:align-items-start gap-2">
                        <div className="text-xl font-bold text-900">{product.name}</div>
                        {/* <Rating value={product.rating} readOnly cancel={false}></Rating> */}
                        <div className="flex align-items-center gap-2">
                            <span className="flex align-items-center gap-1">
                                <i className="pi pi-tag"></i>
                                <span className="font-semibold">{product.category}</span>
                            </span>
                            <Tag value={product.qty_in - product.qty_out <= 0? "OUTOFSTOCK" : 'INSTOCK'} severity={getSeverity(product.qty_in - product.qty_out <= 0? "OUTOFSTOCK" : 'INSTOCK')}></Tag>
                        </div>
                    </div>
                    <div className="flex sm:flex-column align-items-center sm:align-items-end gap-2 sm:gap-1">
                        <span className="text-xl font-semibold">₦{Intl.NumberFormat('en-US').format(product.selling_price)}</span>
                        <Button icon="pi pi-shopping-cart" className="p-button-rounded" disabled={product.qty_in - product.qty_out <= 0}></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const gridItem = (product) => {
    return (
        <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={product.id}>
            <div className="p-4 border-1 surface-border surface-card border-round" onClick={()=>{handleAddProduct(product)}}> 
                <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag"></i>
                        <span className="font-semibold">{product.category}</span>
                    </div>
                    {/* <Tag value={product.inventoryStatus} severity={getSeverity(product)}></Tag> */}
                </div>
                <div className="flex flex-column align-items-center gap-3 py-5">
                    <img className="w-9 shadow-2 border-round" src={product.image} alt={product.name} />
                    <div className="text-1xl font-bold">{product.name}</div>
                    {/* <Rating value={product.rating} readOnly cancel={false}></Rating> */}
                </div>
                <div className="flex align-items-center justify-content-between">
                    <span className="text-2xl font-semibold">₦{Intl.NumberFormat('en-US').format(product.selling_price)}</span>
                    <Tag value={product.qty_in - product.qty_out <= 0? "OUTOFSTOCK" : 'INSTOCK'} severity={getSeverity(product.qty_in - product.qty_out <= 0? "OUTOFSTOCK" : 'INSTOCK')}></Tag>
                </div>
            </div>
        </div>
    );
};

// Custom item template to display customer information
const itemTemplateCustomer = (customer) => (
    <div className="flex align-items-center">
        <div className="mr-2 font-medium">{customer.full_name}</div>
        <small className="text-muted">{customer.card.card_id}</small>
    </div>
);


const itemTemplate = (product, layout, index) => {
    if (!product) {
        return;
    }

    if (layout === 'list') return listItem(product, index);
    else if (layout === 'grid') return gridItem(product);
};

const listTemplate = (products, layout) => {
    return <div className="grid grid-nogutter">{displayedProducts.map((product, index) => itemTemplate(product, layout, index))}</div>;
};

const header = () => {
    return (
        <div className="flex flex-row justify-content-end items-center">
            <div className="card">
                <Toolbar start={startContent} center={centerContent} end={endContent} />
            </div>
            <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
        </div>
    );
};

    const  fetchProducts  = async ()=>{
        try {
            const response = await axios.get('https://api.scan-verify.com/api/sales', {
                headers: {
                    'Content-Type': 'application/json',
                }

            });

            console.log('Customer and Products:', response.data.products);

            setCustomers(response.data.customers)
            // setProducts(response.data.products)


            // )))

            console.log("Logged", fetchedProducts)

        } catch (error) {
            console.error('Error adding product:', error);
        }
     }

     const checkIfLoan = ()=>{
        if (loan !== null) {
            setDue(true);
            alert('HasDue')
        }else{
            alert('Has No Due')
            setDue(false);
        }
     }
//  localStorage.setItem('token',"9|uNcizYdWEBr85RAasqTWNz5cKFCuQKRGTYiOSaeOe4f57c9b")

     const  fetchLoan  = async ()=>{
        try {
            const response = await axios.get(`https://api.scan-verify.com/api/customer/loan?id=${formData.customer_id.id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log('Customer and Products:', response.data.products);

            setRepayment(response.data.repayment)
            setLoan(response.data.loan)
            setNetSal(response.data.netSalary)

            if (response.data.loan !== null) {
                    setDue(true);
            }else{
                setDue(true);
            }
            // )))

            console.log("Logged Repayment", response.data.repayment)
            console.log("Logged Net Salary", response.data.netSalary)
            console.log("Logged Net Loan", response.data.loan)

        } catch (error) {
            console.error('Error adding product:', error);
        }
     }


     const onFilter = (event) => {
        const value = event.filter; // Get the filter input value
        setFilterValue(value);

       console.log("Filter Value", event)

        if (value !== undefined &&value.length >= 5) {
          // Match the input with customer card IDs
          const matchedCustomers = customers.filter((customer) =>
            customer.card.card_id.includes(value)
          );
          setFilteredCustomers(matchedCustomers);
        } else {
          setFilteredCustomers([]); // Clear options if input is less than 5 characters
        }
      };

     
     const verifyPayment  = async ()=>{
        try {
            const response = await axios.get(`https://api.scan-verify.com/api/sales/trans-status/${formData.merchantReference}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log('Sales Status:', response.data);

            setProcessingStatus(response.data.processingStatus)
            setResponseCode(response.data.responseCode)
            setResponseMessage(response.data.responseMessage)


        } catch (error) {
            console.error('Error verifying payment:', error);
        }
     }


     const customerTemplate = (option) => {
        if (isEmploy) {
            return (
                <div>
                    {option.full_name} - {option?.card?.card_id}
                </div>
            );

        } else {
            return (
                <div>
                    {option.full_name} - {option?.card?.card_id}
                </div>
            );
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);


    useEffect(() => {

        if (type.name ==  "Employee") {
            fetchLoan();
        }

    }, [formData.customer_id.id]);



    const handleUpdate = async (e) => {
        e.preventDefault();
        setProcessing(true);

        const form = new FormData();
        // form.append('items', itemFormData.item);
        // form.append('type', itemFormData.type);

        try {
            const response = await axios.post('https://api.scan-verify.com/api/product/create', form, {
                headers: {
                     'Content-Type': 'application/json'
                }
            });

            console.log('Product Item added:', response.data);
            setProcessing(false);

            if (response.data.success) {

                showSuccess('success', "Product", "Product Category/Sub Category  Successfully Added");
            } else {
                showSuccess('error', "Product", "Product failed to add");
            }
        } catch (error) {
            setProcessing(false);
            console.error('Error adding product:', error);
        }
    };

    const selectedCustomerTemplate = (option) => {
        if (isEmploy) {

                // Assuming `option` here is the selected value
        if (!option) return <span>Select Customer</span>;
        return (
            <div>
                {option.full_name} - {option?.card?.card_id}
            </div>
        );

        } else {
                        // Assuming `option` here is the selected value
                if (!option) return <span>Select Customer</span>;
                return (
                    <div>
                        {option.full_name} - {option?.card?.card_id}
                    </div>
                );
        }
    };
    // const onLazyLoad = (event) => {
    //     setLoading(true);
    //     if (loadLazyTimeout.current) {
    //         clearTimeout(loadLazyTimeout.current);
    //     }

    //     // Imitate delay of a backend call
    //     loadLazyTimeout.current = setTimeout(() => {
    //         const { first, last } = event;
    //         // Simulate loading customers here
    //         setLoading(false);
    //     }, Math.random() * 1000 + 250);
    // };

    const onLazyLoad = (event) => {
        setLoading(true);
        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }
    
        loadLazyTimeout.current = setTimeout(() => {
            // Apply filtering manually
            const filterValue = event.filter || '';
            const filteredCustomers = customers.filter(customer =>
                customer?.psn && customer?.psn.includes(filterValue)
            );
       
            // Load a subset of filtered customers
            const { first, last } = event;
            const lazyLoadedCustomers = filteredCustomers.slice(first, last);
    
            setCustomers(filteredCustomers);
            console.log(filteredCustomers);
            setLoading(false);
        }, Math.random() * 1000 + 250);
    };
    
    const footer = <Button label="Verify" className="p-button-raised p-button-success px-2 py-1 bg-green pi pi-tick" onClick={() => setVisible(false)} />;

    return (



        <div className="flex gap-x-12 flex-row sm:flex-row md:flex-col  items-center justify-center  p-12 w-full mx-auto">
            {/* <h1 className=" font-bold mx-auto text-4xl place-self-start text-green-500 justify-center text-left">Palliatve Track</h1> */}

                    <div
                    className='grid grid-cols-1 top-0 mt-0 w-[50%] h-[50%]'
                    >

                     <div className="flex flex-row gap-x-4 w-full">
                           <div className="md:w-1/3 sm:w-full mt-3">
                           <label className="font-bold text-base font-medium text-[#07074D]">
                               Select Customer type
                           </label>
                           <div className="flex gap-2">
                            {customerType.map((option) => (
                                <div key={option.name} className="flex items-center">
                                <RadioButton
                                    variant="outlined"
                                    className="border border-green-500 rounded" // Fix for border-radius
                                    inputId={`radio-${option.name}`}
                                    name="customer_type"
                                    value={option.name}
                                    onChange={()=>{setType(option)}}
                                    checked={type.name === option.name}
                                />
                                <label htmlFor={`radio-${option.name}`} className="ml-2">
                                    {option.name}
                                </label>
                                </div>
                            ))}
                            </div>
                           </div>


                            <div className="card md:w-1/3 sm:w-full my-3">
                            <label htmlFor="customer" className="font-bold text-base font-medium text-[#07074D]">
                                Select Customer
                            </label>
                            <IconField iconPosition='right' >
                            <InputIcon className="pi pi-search" />
                            <AutoComplete
                                inputClassName='w-full px-2' 
                                field="card.card_id"
                                value={selectedCustomer}
                                loading={true}
                                loadingIcon="pi pi-spinner"
                                suggestions={filteredCustomers}
                                completeMethod={searchCustomer}
                                onChange={handleCustomerChange}
                                itemTemplate={itemTemplateCustomer}
                                panelFooterTemplate={panelFooterTemplate}
                                placeholder="Search with Card ID"
                             
                            />
                            </IconField>
                            </div>
                           {/* <div className="card md:w-1/3 sm:w-full my-3">
                            <label htmlFor="cat" className="font-bold text-base font-medium text-[#07074D]">
                                Select Customer
                            </label>
                            <Dropdown
                                inputId="dd-city"
                                loading={loading}
                                filter={true}
                                filterBy="card.card_id"
                                filterPlaceholder="Search with Card ID"
                                onInput={onFilter}
                                value={formData.customer_id}
                                placeholder="Select Customer"
                                name="customer_id"
                                options={filteredCustomers} // Use filtered options
                                onChange={handleCustomerChange}
                                itemTemplate={itemTemplate}
                                valueTemplate={selectedCustomerTemplate} // Custom template for selected item
                                className="w-full text-green-500 border border-green-500"
                            />
                            </div> */}
                           </div>
                           <div className='w-full'>

                            <Modal visible={otpVisible} setVisible={setOtpVisible} className={"md:w-1/2 sm:w-1/2"}  title="OTP Verification">
                            <div className="card flex justify-content-center">
                                    <style scoped>
                                        {`
                                            .custom-otp-input-sample {
                                                width: 48px;
                                                height: 48px;
                                                font-size: 24px;
                                                appearance: none;
                                                text-align: center;
                                                transition: all 0.2s;
                                                border-radius: 0;
                                                border: 1px solid var(--surface-400);
                                                background: transparent;
                                                outline-offset: -2px;
                                                outline-color: transparent;
                                                border-right: 0 none;
                                                transition: outline-color 0.3s;
                                                color: var(--text-color);
                                            }

                                            .custom-otp-input-sample:focus {
                                                outline: 2px solid var(--primary-color);
                                            }

                                            .custom-otp-input-sample:first-child,
                                            .custom-otp-input-sample:nth-child(5) {
                                                border-top-left-radius: 12px;
                                                border-bottom-left-radius: 12px;
                                            }

                                            .custom-otp-input-sample:nth-child(3),
                                            .custom-otp-input-sample:last-child {
                                                border-top-right-radius: 12px;
                                                border-bottom-right-radius: 12px;
                                                border-right-width: 1px;
                                                border-right-style: solid;
                                                border-color: var(--surface-400);
                                            }
                                        `}
                                    </style>
                                    <div className="flex flex-column align-items-center">
                                        <p className="font-bold text-xl mb-2">Verify your phone number</p>
                                        <p className="text-color-secondary block mb-5">Please enter the code sent to {formData.customer_id.contact} Beneficiary registered phone number.</p>
                                        <InputOtp value={token} onChange={(e) => setTokens(e.value)} length={6} inputTemplate={customInput} style={{gap: 0}}/>
                                        <div className="flex justify-content-between mt-5 align-self-stretch">
                                            <button className="btn btn-link btn-sm bg-white text-green-500 px-4 py-2 flex flex-row items-center gap-x-2 focus:ring-2 rounded-sm focus:ring-green-600" style={{padding: 0}}><i className="icon-class"><FaRedo/></i>Resend Code</button>
                                            <button className="btn btn-sm bg-green-500 text-white px-6 py-2 flex flex-row items-center gap-x-2 focus:ring-2 rounded-sm focus:ring-green-600"><i className=""><FaThumbsUp/></i>Verify</button>
                                        </div>
                                    </div>
                                </div>  
                            </Modal>

                           <div className="card flex justify-content-center">
                            <Dialog visible={visible} modal  footer={footer} style={{ width: '50rem' }} onHide={() => {if (!visible) return; setVisible(false); }}>
                            <div className="card w-full h-1/2 mx-auto">
                                       <Image src={formData.customer_id.passport} zoomSrc={formData.customer_id.passport}  preview={true} data-pr-showevent='click' loading='lazy' alt="Image" width="600" height="250"  />
                            </div>
                            </Dialog>
                        </div>
                            
                           {isEmploy &&(
                                    <div className=" mt-5 w-full md:w-full sm:w-full container bg-white rounded-md py-3">
                                    <span className='font-bold text-black capitalize mt-4 px-2'>Loan Summary</span>
                                    <table className='w-full px-6 mb-12'>
                                        <thead>
                                        <tr className='bg-gray-800 rounded-md'>
                                            <th  scope="col" className="px-3 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">Loan</th>
                                            <th  scope="col" className="px-3 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-black-200 px-6 ">
                                            <tr className='px-2'>
                                                <td>
                                                    <b className='text-black px-2'>Salary Net</b>
                                                </td>
                                                <td>
                                                    <b className='text-black px-2'> ₦{new Intl.NumberFormat('en-US').format(netSal ?? 0.0)}</b>
                                                </td>
                                            </tr>
                                            <tr className='px-2'>
                                                <td>
                                                    <b className='text-black px-2'>Current Loan Due:</b>
                                                </td>
                                                <td>
                                                    <b className='text-black px-2'> ₦{new Intl.NumberFormat('en-US').format(repayment?.due_balance ?? 0.0)}</b>
                                                </td>
                                            </tr>
                                            <tr className='px-2'>
                                                <td>
                                                    <b className='text-black px-2'>Loan Amount </b>
                                                </td>
                                                <td>
                                                    <b className='text-black px-2'> ₦{new Intl.NumberFormat('en-US').format(loan?.total_amount ?? 0.0)}</b>
                                                </td>
                                            </tr>

                                            <tr className='px-2'>
                                                <td className='text-black px-2'><b>Eligible Loan Amount:</b> </td>
                                                <td className='text-black px-2'>
                                                    <b>₦{new Intl.NumberFormat('en-US').format((netSal*1/3))}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                           </div>

                           {/* Dropdown for Filtering */}
                           {/* <Dropdown
                            value={filter}
                            options={filterOptions}
                            optionValue='label'
                            optionLabel='label'
                            onChange={onFilterChange}
                            placeholder="Select a Category"
                            className="p-mb-3"
                        /> */}
                        
                         <div className="card">
                            <DataView value={products}  listTemplate={listTemplate}  lazy={true} loading={isLoading} layout={layout} header={header()} />
                        </div>
                        <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={fetchedProducts.length}
                        rowsPerPageOptions={[1,2,3]}
                        onPageChange={onPageChange}
                    />
                </div>

            <div className="mx-auto w-[65%]     h-[50%] bg-white p-6 rounded-md justify-between ">
            {/* <h1 className=" font-bold mx-auto text-4xl place-self-start text-green-500 justify-center text-left">POS Palliatve Shop</h1> */}
            <Toast ref={toast} />
                <form>
                    <div className="grid grid-cols-2 gap-x-4 w-full">
                    
    
                           
                                {/* <div className="card  w-full mt-3">
                                <label htmlFor="cat" className="mt-4 block font-bold text-base font-medium text-[#07074D]">
                                    Select Product
                                </label>
                                        <Dropdown inputId="dd-city"  value={formData.products} filter={true}  placeholder='Select Products'  name='products' onChange={handleAddProduct} options={products} optionLabel="name" className="w-full text-green-500 border border-green-500" />
                                </div> */}

                                <label htmlFor="cat" className="mt-4 mb-3 block font-bold text-base font-medium text-2xl text-[#07074D]">
                                Checkout
                                </label>
                                <div className="w-full flex flex-row items-center">                          
                                    <div className="flex flex-wrap gap-3">
                                    {methods.map((method) => (
                                        <div key={method.id} className="flex align-items-center">
                                        <RadioButton variant='outlined' className='border border-green-500 border-rounded' inputId={method.id} name="payment_type" value={method.id} onChange={handlePaymentChange} checked={formData.payment_type === method.id} />
                                        <label htmlFor={method.id} className="ml-2 font-bold">{method.name}</label>
                                        </div>
                                    ))}
                                    </div>

                                    {loanType == "installment" && (
                                <>
                                    <label htmlFor="cat" className="mt-4  block font-bold text-base font-medium text-[#07074D]">
                                    Installments (In Months)
                                    </label>

                                    <input
                                    type="number"
                                    max={2}
                                    value={1}
                                    min={1}
                                    name="installments"
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-200 sm:text-sm"
                                    placeholder="Installments"
                            
                                    required
                                    />
                                </>
                                                           
                               )}

                                </div>

                               


                        {/* {loanType == "card" && (
                                <>
                                <label  className="mt-3 block text-base font-medium font-bold text-[#07074D]">
                                Card ID
                                </label>
                                <input
                                    id="card_id"
                                    type="password"
                                    name="card_id"
                                    maxLength={5}
                                    minLength={1}                                            
                                    required={true}
                                    placeholder="Card ID"
                                    value={formData?.card_id}
                                    onChange={handleInputChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-200 sm:text-sm"
                                />

                                </> 
                                )} */}
                            </div>


                    <div className="w-full mt-4">
                    <label className="block text-sm font-medium font-bold text-black mt-2">Picked Products</label>
                    <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-green-500">
                        <tr>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium font-bold text-white uppercase tracking-wider">Product(s)</th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium font-bold text-white uppercase tracking-wider">Price</th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium font-bold text-white uppercase tracking-wider">Stocks</th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium font-bold text-white uppercase tracking-wider">Quantity</th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium font-bold text-white uppercase tracking-wider">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="bg-white py-1 divide-y divide-x divide-gray-200">
                        {formData.products.map((product, index) => (
                            <tr key={index} className="font-bold">

                            <td className="px-0 py-0 whitespace-nowrap">
                                <span>
                                    {product.name}
                                </span>
                                {/* <input
                                type="text"
                                name="id"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-200 sm:text-sm"
                                placeholder="Product ID"
                                value={product.name}
                                disabled={true}
                                required
                                /> */}
                            </td>
                            <td className="px-4 py-0 whitespace-nowrap">
                                <span>
                                ₦{Intl.NumberFormat('en-NG').format(product.selling_price)} </span>
                                {/* <input
                                type="text"
                                name="selling_price"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-200 sm:text-sm"
                                placeholder="Selling PRice"
                                value={product.selling_price}
                                disabled={true}
                                required
                                /> */}
                            </td>
                            <td className="px-4 py-0 whitespace-nowrap text-red-500 ">
                                <span className=''>
                                    {product.stock}
                                </span>
                                {/* <input
                                type="number"
                                name="stock"
                                className="mt-1 block w-full px-3 py-2 border font-bold text-red-500 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-200 sm:text-sm"
                                placeholder="Quantity"
                                disabled={true}
                                value={product.stock}
                                onChange={(e) => handleProductChange(index, e)}
                                required
                                /> */}
                            </td>
                            <td className="px-4 py-0 whitespace-nowrap">
                                <div className="flex items-center">
                                    <button
                                    type="button"
                                    className="inline-flex items-center px-2 py-2 border border-transparent rounded-md shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    onClick={() => handleDecrementProduct(index)}
                                    >
                                    <FaMinus/>
                                    </button>
                                    <input
                                    type="text"
                                    name="quantity"
                                    className="mt-1 block w-16 px-3 py-2 bg-green-300 mx-3 border-none outline-none sm:text-sm text-center"
                                    placeholder="Quantity"
                                    value={product.quantity}
                                    onChange={(e) => handleProductChange(index, e)}
                                    required
                                    />
                                    <button
                                    type="button"
                                    className="inline-flex gap-x-2 items-center px-2 py-2 border border-transparent rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                    onClick={() => handleIncrementProduct(index)}
                                    >
                                    <FaPlus/>
                                    </button>
                                </div>
                            </td>
                            <td className="px-4 py-0 whitespace-nowrap">
                                <button
                                type="button"
                                className="inline-flex items-center px-1 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                onClick={() => handleRemoveProduct(index)}
                                >
                                <FaTrash/>
                                </button>
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>

                     {isEmploy &&(
                        <div className='my-5'>
                            <table className='w-full'>
                                <tbody className=''>
                                    <tr className='bg-yellow-500 my-4'>
                                                    <td className='font-bold'>Current Net: </td>
                                                <td className='font-bold'>
                                                  ₦{new Intl.NumberFormat('en-US').format(netSal)}
                                                </td>
                                    </tr>
                                    <tr className='bg-black'>
                                        <td className='text-white font-bold'>Eligible Loan Amount: </td>
                                        <td className='text-white font-bold'>
                                            ₦{new Intl.NumberFormat('en-US').format((netSal*1/3))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                       </div>
                        )
                     }


                        <div className=" my-5 bg-gray-200">
                        <span className='font-bold  text-green-600 text-capitalize mt-5 py-6'>Cummulative Price</span>
                        <table className=' w-full'>
                            <thead>
                               <tr className='bg-green-500'>
                                <th  scope="col" className="px-3 py-1 text-left text-xs font-medium font-bold text-white uppercase tracking-wider">S/N</th>
                                <th scope="col" className="px-3 py-1 text-left text-xs font-bold font-medium text-white uppercase tracking-wider">Items Name</th>
                                <th scope="col" className="px-3 py-1 text-left text-xs font-bold font-medium text-white uppercase tracking-wider">Qty</th>
                                <th scope="col" className="px-3 py-1 text-left text-xs font-bold font-medium text-white uppercase tracking-wider">Amount</th>
                               </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 ">
                                {
                                        formData.products.map((product, index) => (
                                            <tr key={index}>
                                            <td className='px-2 py-1 whitespace-nowrap font-bold'>{index + 1}</td>
                                            <td className='px-2 py-1 whitespace-nowrap font-bold'>{product.name}</td>
                                            <td className='px-2 py-1 whitespace-nowrap font-bold'>{product.quantity}</td>
                                            <td className='px-2 py-1 whitespace-nowrap font-bold'>
                                            ₦{new Intl.NumberFormat('en-US').format(product.selling_price)}
                                                </td>
                                            </tr>
                                        ))
                                }



                            </tbody>
                            </table>
                                    <span className='text-red-500 font-bold mt-4'>Spendable Balance (₦{new Intl.NumberFormat('en-US').format(netSal)}):
                                    ₦{new Intl.NumberFormat('en-US').format(totalSpendable)}    
                                    </span><br/>
                                    <span className='text-green-500 font-bold mt-4'>Total Amount + Charges: </span>
                                    <span className='font-extrabold'>
                                    ₦{new Intl.NumberFormat('en-US').format(totalAmount+totalCharges)} 
                                    </span>
                            </div>
                           


                    {/* <div className="mb-5">
                        <label htmlFor="note" className="mb-3 block text-base font-medium font-bold text-[#07074D]">
                        Description
                        </label>
                        <textarea
                            id="note"
                            name="note"
                            placeholder='Notes'
                            onChange={handleInputChange}
                            className="w-full rounded-md border border-green-500 bg-white py-3 px-5 text-base font-medium text-[#26ad38] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        >{formData.note}</textarea>
                    </div> */}

                    {disable? (
                            <>
                                <span className='text-red-500 font-bold bg-yellow-300 rounded-md px-2 py-1'>Your Total cost exceeds limit of the loan eligibility, you need to decrease your porudcts quantity or remove </span>

                                <div>
                                <button
                                    onClick={handleSubmit}
                                    
                                        { ...processing && 'disabled'}
                                        disabled={true}

                                        className={`hover:shadow-form grid  mt-4 grid-cols-2 w-full items-center justify-content-center file:rounded-md  py-3 px-4 text-center text-base font-semibold outline-none bg-green-300`}
                                    >{processing && (
                                        <div className="items-center">
                                            <ProgressSpinner style={{width: '30px', height: '30px', color:'red'}} strokeWidth="6" fill="none" animationDuration=".2s" />
                                    </div>
                                    )


                                    }
                                    <div className="items-center justify-center text-white">
                                        <FaCartPlus/>
                                        <span>Process Sale</span>
                                    </div>
                                    </button>
                                </div>
                            </>


                    ):(

                    <div>
                        <button
                        onClick={handleSubmit}
                            type="submit"
                            { ...processing && 'disabled'}

                            className={`hover:shadow-form focus:bg-green-300  grid grid-cols-2 w-full items-center justify-content-center file:rounded-md  py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-green-300' : 'bg-green-500'}`}
                        >{processing && (
                             <div className="items-center">
                                <ProgressSpinner style={{width: '30px', height: '30px', color:'red'}} strokeWidth="6" fill="none" animationDuration=".2s" />
                        </div>
                        )

                        }
                        <div className="flex flex-row gap-x-4 items-center justify-center text-white">
                                <FaCartPlus/>
                                <span>Process Sale</span>
                        </div>
                        </button>
                    </div>
                    )

                }

                </form>
            </div>
        </div>
    );
}
